body {
  font-family: 'Roboto Condensed', sans-serif !important;
  background: linear-gradient(180deg, #124471 0%, #4E85AE 52.6%, rgba(125, 161, 197, 0.5) 100%);
  color: #fff;
  font-size: 20px;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  display: flex;
  align-items: center;
}

.nav-item {
  flex: 1;
}

.navbar a {
  color: #fff !important;
  padding: 0 5px;
  margin: 0 5px;
  text-decoration: none;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .navbar a {
    margin-bottom: 10px;
  }
}

.navbar a:hover {
  cursor: pointer;
}

.navbar .active {
  font-weight: 700;
}

.navbar-toggler {
  border: 0;
}

@media only screen and (min-width: 768px) {
  .navbar .social-icons {
    justify-content: flex-end !important;
  }
}

.social-icons a {
  padding: 0 5px !important;
  margin: 0;
  filter: drop-shadow(0px 0px 5px white);
}

.bio img {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.bio .desc {
  margin-top: 1rem;
  color: #000;
}

.mixes iframe {
  margin-bottom: 12px;
}

.event {
  padding: 10px;
  width: 25%;
  flex-grow: 1;
}

@media (max-width: 991.98px) {
  .event {
    width: 50%;
  }
}

@media (max-width: 767.98px) {
  .event {
    width: 100%;
  }
}

.event img, .event video {
  width: 100%;
  height: 230px;
  object-fit: contain;
}

.event video {
  height: 230px;
}

.event p {
  font-size: 15px;
}

.event.video p:first-of-type {
  margin-top: -4px;
}

.contact {
  margin-bottom: 16px;
}

.contact a {
  color: #fff;
}

.contact button {
  display: block;
  margin: auto;
  padding: 10px 20px;
  font-size: 20px;
  background: #124471;
  border: #124471;
}

.contact button:hover {
  background: #124471;
}

.footer {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  margin-bottom: 30px;
}